.updateCollapse {
   margin-top: 0.5vw;
}

.deleteButton {
   margin-left: 2vw;
}

.deleteIsOn {
   margin-left: 2vw;
}

.deleteText {
   color: red;
   margin-left: 2vw;
   font-size: 1.5rem;
}