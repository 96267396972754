/* @import "~antd/dist/antd.css"; */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

html {
  background-color: #f8f8f8;
  height: 100%;
}

body {
  background-color: #f8f8f8 !important;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: Poppins;
}

.ant-input-password input {
  background-color: transparent;
}

.ant-input-password:hover {
  border-color: black !important;
}
