.form {
  max-width: 75vw;
  min-width: 40vw;
  border: 1px solid white;
  border-radius: 20px;
  background-color: white;
  padding: 70px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.logo {
  /* min-width: 50vw; */
  max-width: 50vw;
}
.formInput,
.formInput:is(:focus, :hover) {
  outline: 0;
  border: 0;
  background: transparent;
  border-bottom: 1px solid black;
  border-radius: 0px;
  padding: 4px 0px;
  box-shadow: none;
}

@media (max-width: 768px) {
  .form {
    padding: 60px;
    min-width: 70vw;
  }
}
@media (max-width: 992px) {
  .form {
    padding: 20px;
    min-width: 60vw;
  }
}
