.contentDiv {
    border-bottom: 1px solid black;
    margin: 2px;
    padding: 5px;
    overflow: scroll;
}

.textArea {
    margin-top: 0.3vw;
}

.addContentButton {
    float: right;
    margin-top: 0px;
    padding-top: 0px;
    position: relative;
    top: 10px;
    background-color: blue;
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px
}

.addContentButton:hover {
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
}