.filter {
  padding: 1rem 0;
}

.inputWrapper {
  display: flex;
  width: 50%;
}

.filterInput,
.filterInput:is(:focus, :active, :hover) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  outline: 0;
  border: none;
  box-shadow: none;
  height: 100%;
}

.submitButton {
  border: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: none !important;
}

@media (max-width: 992px) {
  .inputWrapper {
    width: 75%;
  }
}
@media (max-width: 768px) {
  .inputWrapper {
    width: 100%;
  }
}
