.filter {
   
    width:80%;
  }
  
  .inputWrapper {
    display: flex;
   
  }
  
  .submitButton {
    border: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: none !important;
  }
  
  @media (max-width: 992px) {
    .inputWrapper {
      width: 75%;
    }
  }
  @media (max-width: 768px) {
    .inputWrapper {
      width: 100%;
    }
  }