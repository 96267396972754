.container {
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginContainer {
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.2;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image {
  width: 75%;
}

.text {
  color: red;
  font-size: 2rem;
  font-weight: 700;
}

.button {
  background-color: var(--primary);
  color: #fff;
  font-weight: 700;
  border: none;
  border-radius: 5em;
  width: 40%;
  padding: 1rem;
  cursor: pointer;
}
