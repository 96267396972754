.wrappperDiv {
  padding: 30px;
  background: #ececec;
}

.form {
  display: flex;
  flex-direction: column;
}

.couponType {
  margin: 0 0 20px auto;
}
