.wrapper {
  margin-top: 1rem;
}
.detailRows {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.5rem 0.25rem 0.5rem;
  border-bottom: 1px solid gray;
  font-size: medium;
}
