.emailForm{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
    margin-left:11px;
   
    
}

.emailInput{
    outline: none;
    border: 1px solid rgb(211, 207, 207);
    border-radius: 2px;
    margin-right: 1rem;
    width: 50%;
    padding: 5px 10px;
}
